<template>

<div class="showPage">
  <v--hard></v--hard>
  <div style="width: 75%;margin: 0 auto;padding-top: 20px">
    <div style="width: 70%;float: left">
      <v-article></v-article>
    </div>
    <div style="width: 25%;background-color: #475669;height: 1000px;float: right">
        <v-category ></v-category>
    </div>
  </div>
<v-Foot></v-Foot>
</div>
</template>

<script>
import About from "@/components/About";
import Image from "@/views/Image";
import Hard from "@/components/Hard";
import Category from "@/components/category/category";
import Article from '@/components/article/Article';


export default {
  name: "showPage",
  components: {
    'v-Image':Image,
    'v-About':About,
    'v-Hard':Hard,
    'v-category':Category,
    'v-article' :Article

  },
  data() {
    return {
      url: [{src:require('../assets/img/3.jpg')},{src:require('../assets/img/3.jpg')}],
      fit: 'fill',
    };
  },

  methods: {
  },
}
</script>

<style scoped>

.BTi {
  font-family:"Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  font-size: 20px;
  font-weight: bold;
}
</style>