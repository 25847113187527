<template>
<div class="category">
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span>标签</span>
    </div>
    <div v-for="s in category" :key="s.index" class="text item">
      <div style="float: left;margin: 5px;ba"> <el-button :style="{'color':s.categoryColor,'background-color':prima}" @click="dianji(s.categoryId)" round>{{s.categoryName}}</el-button> </div>

      <div style="background-color: "></div>
    </div>
  </el-card>
</div>
</template>

<script>
export default {
  name: "category",
  data(){
    return{
      prima:'aliceblue',
      category:[]
    }
  },
  created() {
    const _this = this
    axios.post('https://www.realmmy.com/api').then(function (resp) {
      _this.category = resp.data
    })
  },
  methods: {
    // handleSelect(key, keyPath) {
    //   console.log(key, keyPath);
    // },
    dianji:function (a){

      this.$router.push({
        path: '/showPage',
        query: {
          id: a
        }
      })
    }

  }
}
</script>

<style scoped>

</style>
