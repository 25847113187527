<template>
<div class="Article">
  <div style="padding-top: 30px" v-for="articles in article" >
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <el-link  v-on:click="Test(articles.articleId)"   target="_blank">
          <span class="BTi">{{articles.articleTitle}}</span>
        </el-link>
      </div>
      <div class="text item">
        <span>{{articles.articleAbstract}}</span>
        <div style="margin-top: 20px">
          <i class="el-icon-user-solid"><span style="margin-left: 10px">{{articles.articleAuthor}}</span></i>
          <i class="el-icon-view" style="padding-left: 20px"><span style="margin-left: 10px">{{articles.articleViewCount}}</span></i>
          <i class="el-icon-chat-dot-round" style="padding-left: 20px"><span style="margin-left: 10px">{{articles.articleCategory}}</span></i>
          <i class="el-icon-time" style="padding-left: 20px"><span style="margin-left: 10px">{{articles.articleTime}}</span></i>
        </div>
      </div>
    </el-card>
  </div>
  <div style="padding-top: 20px">
    <el-pagination
        background
        @current-change="handleCurrentChange"
        layout="prev, pager, next"
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="PageSize"
        :total="totalCount">
    </el-pagination>
  </div>
</div>
</template>

<script>
export default {
  name: "Article",
  data(){
    return{
      article:[],
      id:'',
      // 默认显示第几页
      currentPage:1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount:'',
      // 个数选择器（可修改）
      pageSizes:[1,2,3,4],
      // 默认每页显示的条数（可修改）
      PageSize:10,
    }
  },
  props:["datas"],
  watch:{
    "$route.query.id": "fetch"
  },

  created:function() {
    this.id=this.$route.query.id
    const _this = this
    this.$axios.get('https://www.realmmy.com/api/show/',{
      params:{
        articleCategory:this.id,
        limit:10,
        page:0
      }}).then(function (res) {
      _this.article = res.data
    });
    this.$axios.get('https://www.realmmy.com/api/show/articleCount',{
      params:{
        articleCategory:this.id
      }}).then(function (resp) {
      _this.totalCount = resp.data
    });
  },
  methods: {
    fetchData: function(page,limit){
      this.id=this.$route.query.id
        const _this = this
      this.$axios.get('https://www.realmmy.com/api/show/',{
          params:{
            articleCategory:this.id,
            limit:limit,
            page:page
          }}
          ).then(function (resp) {
          _this.article = resp.data
        });
    },
    fetch:function(){
      this.id=this.$route.query.id
      const _this = this
      this.$axios.get('https://www.realmmy.com/api/show/',{
        params:{
          articleCategory:this.id,
          limit:10,
          page:0
        }}
      ).then(function (resp) {
        _this.article = resp.data
      });
    },
    handleCurrentChange:function(val) {
      // 改变默认的页数
      this.currentPage=val
      // 切换页码时，要获取每页显示的条数
      this.fetchData((val-1)*(this.PageSize),this.PageSize)
    },
    Test:function(a){
      this.$router.push({
        path: '/article',
        query: {
          id: a
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
