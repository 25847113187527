<template>
  <div class="image">
    我的世界
      <table>
        <tr>
          <td>名称</td>
          <td>编号</td>
          <td>地址</td>
        </tr>
        <tr v-for="item in books">
          <td>{{item.imagePanID}}</td>
          <td>{{item.imagePanName}}</td>
          <td>{{item.imagePanType}}</td>
        </tr>
      </table>
    {{msg}}
  </div>
</template>

<script>
export default {
  name: "Image",
  data(){
    return {
      msg:'hello world',
      books:[
        {
          id:1,
          name:'这是第一本',
          author:'佚名'
        },
        {
          id:2,
          name:'这是第一本',
          author:'佚名'
        },
        {
          id:3,
          name:'这是第一本',
          author:'佚名'
        }
      ]
    }

  },
  created() {
    const _this=this
   axios.get('http://47.103.147.94:8082/').then(function (resp){
    _this.books=resp.data
   })
  }
}
</script>

<style scoped>

</style>